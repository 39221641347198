import React from 'react'
import { UseFormRegister, FieldValues, FieldErrors } from 'react-hook-form'

export const Input = ({
  register,
  name,
  required,
  errors,
  label,
  placeholder,
  type,
}: {
  register: UseFormRegister<FieldValues>
  name: string
  required: boolean
  errors: FieldErrors<FieldValues>
  label: string
  placeholder?: string
  type: string
}) => (
  <input
    className={`w-full rounded-lg border border-gray-900 p-3`}
    {...register(name, {
      required,
    })}
    placeholder={placeholder || `Enter your ${label}`}
    type={type}
    aria-invalid={errors[name] ? 'true' : 'false'}
  />
)
