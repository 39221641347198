import React, { useEffect, useRef, useState } from 'react'
import { ImageType } from '../../templates/Generic'
import { Spacing, useSpacing } from '../hooks'
import { TextComponent, TextComponentProps } from '../TextComponent'
import { Carousel } from './Carousel'

export interface LogosComponentProps {
  data: {
    logoList: ImageType[]
    presentationType: 'static' | 'rotating'
    spacing: Spacing[]
    titleSection: TextComponentProps[]
  }
}

export const LogosComponent = ({ data }: LogosComponentProps) => {
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  const [listception, setListception] = useState<
    LogosComponentProps['data']['logoList'][]
  >([])

  useEffect(() => {
    let list = []
    for (let x = 0; x < data.logoList.length; x += 5) {
      list.push(data.logoList.slice(x, x + 5))
    }
    setListception(list)
  }, [])

  return (
    <div className={`max-w-page px-4 md:mx-auto md:px-24 ${spacing}`}>
      <TextComponent data={data.titleSection} />

      {data.presentationType === 'static' && (
        <ul className="grid grid-cols-2 gap-8 sm:grid-cols-7 md:grid-cols-9 lg:grid-cols-11">
          {data.logoList.map((logo, index) => (
            <li
              key={`${logo.url}_${index}`}
              className={`sm:col-span-2
            sm:[&:nth-child(6n-2)]:col-start-2
            md:[&:nth-child(6n-2)]:col-start-auto
            md:[&:nth-child(8n-3)]:col-start-2
            lg:[&:nth-child(8n-3)]:col-start-auto
            lg:[&:nth-child(10n-4)]:col-start-2`}
            >
              <img
                className="h-[64px] w-[160px]"
                src={logo.url as string}
                alt={logo.alt || logo.filename || ''}
              />
            </li>
          ))}
        </ul>
      )}

      {data.presentationType === 'rotating' && (
        <div className="h-[256px] md:h-[64px]">
          <Carousel
            transition={2000}
            interval={5000}
            items={listception.map(list => (
              <ul className="grid grid-cols-2 gap-8 md:grid-cols-5">
                {list.map(logo => (
                  <li>
                    <img
                      className="h-[64px] w-[160px]"
                      src={logo.url as string}
                      alt={logo.alt || logo.filename || ''}
                    />
                  </li>
                ))}
              </ul>
            ))}
          />
        </div>
      )}
    </div>
  )
}
