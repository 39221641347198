import React from 'react'
import { breakpoints } from '../consts'
import { useMediaQuery } from '../hooks'
import {
  FooterCopyright,
  FooterLogo,
  FooterMenu,
  FooterSocial,
  FooterSubscribe,
} from './Components'

export const Footer = () => {
  const { matches: renderMobile } = useMediaQuery(breakpoints.down('xl'))
  return (
    <div className="max-w w-full bg-blue-900 text-white">
      <div className="grid w-full max-w-page grid-cols-1 justify-items-center gap-[32px] px-4 pb-16 md:mx-auto xl:grid-cols-[160px_3fr_1fr] xl:gap-2 xl:px-12">
        {renderMobile ? (
          <>
            <FooterLogo className="justify-self-center" />
            <FooterMenu />
            <div className="grid w-full grid-cols-1">
              <FooterSubscribe />
              <FooterSocial />
            </div>
            <FooterCopyright />
          </>
        ) : (
          <>
            <div className="flex flex-col justify-between text-blue-400">
              <FooterLogo />
              <FooterCopyright />
            </div>
            <FooterMenu />
            <div className="w-full pt-8">
              <FooterSubscribe />
              <FooterSocial />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
