import { useState, useEffect } from 'react'

/** Returns a boolean matches, which returns true if the breakpoint
 * parameter passed matches the current media screen size.
 * - Example: const { matches } = useMediaQuery(breakpoints.down('lg'));
 */
export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(window?.matchMedia(query).matches)
  useEffect(() => {
    const media = window?.matchMedia(query)
    const listener = () => {
      setMatches(media.matches)
    }

    if (media.matches !== matches) setMatches(media.matches)
    media.addEventListener('change', listener)

    return () => media.addEventListener('change', listener)
  }, [matches, query])

  return { matches }
}
