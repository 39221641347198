import clsx from 'clsx'
import { PageProps } from 'gatsby'
import React from 'react'
import { Spacing, useSpacing } from '../hooks'
import { SmartLink } from '../Link'

export type BlogTagNavProps = {
  data: {
    tags: {
      name: string
    }[]
    showAll?: boolean
    spacing: Spacing[]
  }
  location: PageProps['location']
}

export const BlogTagNav = ({ data, location }: BlogTagNavProps) => {
  const spacing = useSpacing(data.spacing?.[0] as Spacing)

  return (
    <div
      className={clsx(
        'flex max-w-page flex-wrap gap-5 px-4 md:mx-auto md:flex-nowrap md:px-24',
        spacing
      )}
    >
      {data?.showAll && (
        <SmartLink
          activeClassName="text-gray-900 font-black"
          inactiveClassName="text-gray-700 font-semibold"
          linkTo={location.pathname}
        >
          All
        </SmartLink>
      )}
      {data?.tags?.map(tag => (
        <SmartLink
          key={tag.name}
          activeClassName="text-gray-900 font-black"
          inactiveClassName="text-gray-700 font-semibold"
          linkTo={`${location.pathname}?tag=${tag.name
            .replace(/\s/g, '%20')
            .toLowerCase()}`}
        >
          {tag.name}
        </SmartLink>
      ))}
    </div>
  )
}
