import React from 'react'
import { UseFormRegister, FieldValues, FieldErrors } from 'react-hook-form'

export const Checkbox = ({
  register,
  name,
  required,
  errors,
}: {
  register: UseFormRegister<FieldValues>
  name: string
  required: boolean
  errors: FieldErrors<FieldValues>
}) => (
  <input
    className="mr-2 h-4 w-4 accent-blue-700"
    {...register(name, {
      required,
    })}
    type="checkbox"
    aria-invalid={errors[name] ? 'true' : 'false'}
  />
)
