import React, { useState, useEffect } from 'react'
import { wait } from '../../utils/wait'

interface CarouselProps {
  cycle?: boolean
  interval?: number
  transition?: number
  items: any[]
}

export const Carousel = ({
  cycle = true,
  items,
  interval = 5000,
  transition = 5000,
}: CarouselProps) => {
  const [active, setActive] = useState(0)
  const [firstTransitionIsDone, setFirstTransitionIsDone] = useState(false)

  useEffect(() => {
    if (!cycle) return
    const timeout = setTimeout(() => {
      async function startImageTransition() {
        if (firstTransitionIsDone) await wait(transition)
        setActive(active === items.length - 1 ? 0 : active + 1)
        setFirstTransitionIsDone(true)
      }
      if (cycle) {
        startImageTransition()
      }
    }, interval)

    return () => clearTimeout(timeout)
  }, [active, firstTransitionIsDone, cycle, items, interval, transition])

  return (
    <div className="relative h-full w-full overflow-hidden">
      {items.map((item, index) => (
        <div
          className="absolute h-[inherit] w-[inherit] bg-cover bg-center"
          key={`${item?.toString() || item}-${index}`}
          style={{
            opacity: active === index ? 1 : 0,
            transition: `opacity ${transition}ms`,
          }}
        >
          {item}
        </div>
      ))}
    </div>
  )
}
