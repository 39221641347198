import clsx from 'clsx'
import React from 'react'
import { Button, type ButtonProps, Markdown } from '..'
import { Spacing, useSpacing } from '../hooks'

export interface TextComponentProps {
  id: string
  anchorTag: string
  limitWidth: boolean
  textPosition: 'left' | 'center' | 'right'
  title: string
  label: string
  spacing: Spacing[]
  subhead: string
  content: {
    id: string
    content: string
  }[]
  button: ButtonProps[]
}

export const TextComponent = ({
  data,
  standalone = false,
  className = '',
}: {
  data: TextComponentProps[]
  standalone?: boolean
  className?: string
}) => {
  const spacing = useSpacing(data?.[0]?.spacing?.[0] as Spacing)
  return data.length > 0 ? (
    <div
      id={data[0].anchorTag}
      className={clsx(
        className,
        {
          'flex-cols flex max-w-page p-4 md:mx-auto md:px-24': standalone,
          'justify-center': data?.[0].textPosition === 'center',
          'justify-end': data?.[0].textPosition === 'right',
          'justify-start': data?.[0].textPosition === 'left',
        },
        spacing,
      )}
    >
      {data?.map(text => (
        <div
          key={text.id}
          className={clsx({
            'md:w-[910px]': text.limitWidth,
            'text-center': text.textPosition === 'center',
            'text-right': text.textPosition === 'right',
            'text-left': text.textPosition === 'left',
          })}
        >
          <span className="py-2 text-sm font-semibold text-blue-700">
            {text.label}
          </span>
          <h2 className="py-2 font-MaisonNeueExtended text-3xl font-bold text-black md:text-5xl">
            {text.title}
          </h2>
          <h3 className="pb-8 text-2xl text-gray-700">{text.subhead}</h3>
          <div
            className={clsx(`text-lg font-normal text-gray-700`, {
              'pb-8': text.content.length > 0,
            })}
          >
            {text.content.map((item, index) => (
              <div key={item.id} className={clsx({ 'pt-2': index !== 0 })}>
                <Markdown markdown={item.content} />
              </div>
            ))}
          </div>
          {text?.button?.length > 0 &&
            text.button.map((button, index) => (
              <Button
                key={button.label}
                linkTo={button.linkTo}
                btnType={button.btnType}
                size={button.size}
                className={`${index > 0 && 'ml-4'}`}
              >
                {button.label}
              </Button>
            ))}
        </div>
      ))}
    </div>
  ) : null
}
