import React, { AnchorHTMLAttributes } from 'react'
import clsx from 'clsx'
import { SmartLink } from '..'

export type ButtonProps = {
  id?: string
  children: React.ReactNode
  className?: string
  linkTo?: string
  btnType?: 'primary' | 'light' | 'outline' | 'icon'
  size?: 'small' | 'medium' | 'large' | 'full'
  [key: string]: any
}

/** Creates a button on the page */
export const Button = ({
  id,
  children,
  className = '',
  linkTo = '',
  btnType = 'primary',
  size = 'medium',
  ...rest
}: ButtonProps) => (
  <SmartLink linkTo={linkTo}>
    <button
      key={id}
      id={id}
      {...rest}
      className={clsx(
        'relative z-[1] flex items-center justify-center whitespace-nowrap',
        'border border-solid text-center font-semibold',
        'before:absolute before:left-0 before:top-0 before:-z-[1] before:h-full before:w-full',
        'before:opacity-0 before:transition-opacity before:duration-300 hover:before:opacity-100',
        {
          'rounded-[100px] px-4 py-2 text-sm before:rounded-[100px]':
            size === 'small' && btnType !== 'icon',
          'rounded-[100px] px-8 py-3 text-base before:rounded-[100px]':
            size === 'medium' && btnType !== 'icon',
          'rounded-[100px] px-12 py-5 text-xl before:rounded-[100px]':
            size === 'large' && btnType !== 'icon',
          'w-full rounded-[200px] px-12 py-5 text-xl before:rounded-[200px]':
            size === 'full' && btnType !== 'icon',

          [`
          border-transparent bg-gradient-to-br from-blue-700 to-[#3C70C7] text-gray-100
          before:bg-gradient-to-br before:from-blue-900 before:to-[#24467F]
          `]: btnType === 'primary',
          'border-transparent bg-gradient-to-br from-[#DEE897] to-[#CCDE44] text-gray-900 before:bg-gradient-to-br before:from-[#CBDA58] before:to-[#A5B620]':
            btnType === 'light',
          'border-gray-700 bg-transparent text-gray-700': btnType === 'outline',

          'rounded-[200px] border-gray-700': btnType === 'icon',
          'p-2': btnType === 'icon' && size === 'small',
          'p-3': btnType === 'icon' && size === 'medium',
          'p-4': btnType === 'icon' && size === 'large',
        },

        className,
      )}
    >
      {children}
    </button>
  </SmartLink>
)
