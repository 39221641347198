import React, { useEffect } from 'react'

/**
 * Hook that alerts clicks outside of the passed ref
 */
export const useOnClickOutside = (
  ref: React.RefObject<any>,
  action: any
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }
      action(event)
    }
    document.addEventListener('mousedown', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, action])
}
