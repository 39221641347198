import React from 'react'
import { FieldErrors, FieldValues } from 'react-hook-form'

export const Label = ({
  label,
  helperText,
  name,
  errors,
}: {
  label: string
  helperText?: string
  name: string
  errors: FieldErrors<FieldValues>
}) => (
  <label className="pb-2 font-semibold text-gray-800">
    {label}
    {helperText && (
      <div className="pb-2 text-xs font-normal text-gray-600">{helperText}</div>
    )}
    {errors[name] && (
      <p className="text-sm font-normal text-red-500" role="alert">
        {label} required
      </p>
    )}
  </label>
)
