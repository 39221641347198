import { defaultOptions } from 'gatsby-plugin-mdx/dist/plugin-options'
import React from 'react'
import { UseFormRegister, FieldValues, FieldErrors } from 'react-hook-form'

export const Dropdown = ({
  register,
  name,
  required,
  errors,
  label,
  placeholder,
  options,
}: {
  register: UseFormRegister<FieldValues>
  name: string
  required: boolean
  errors: FieldErrors<FieldValues>
  label: string
  placeholder?: string
  options: string[]
}) => (
  <select
    className={`w-full rounded-lg border border-gray-900 p-3`}
    aria-invalid={errors[name] ? 'true' : 'false'}
    {...register(name, {
      required,
    })}
    defaultValue=""
  >
    <option value="" disabled>
      {placeholder}
    </option>
    {options.map(option => (
      <option key={option} value={option}>
        {option}
      </option>
    ))}
  </select>
)
