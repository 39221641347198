import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { SmartLink } from '../Link'

/** Renders markdown using react-markdown, with custom components
 * to support lists and links opening in new tabs
 */
export const Markdown = ({ markdown }: { markdown: string }) => (
  <ReactMarkdown
    children={markdown}
    components={{
      a: ({ node, ...props }) => (
        <SmartLink
          {...props}
          linkTo={props.href}
          className="inline underline"
        />
      ),
      // Remove ordered from props to avoid React warning
      ul: ({ node, ordered, ...props }) => (
        <ul className="list-disc ps-10" {...props} />
      ),
      ol: ({ node, ordered, ...props }) => (
        <ol className="list-decimal ps-10" {...props} />
      ),
    }}
    remarkPlugins={[remarkGfm]}
  />
)
