import React from 'react'
import LottiePlayer from 'react-lottie-player'

export const Lottie = ({ lottieFile }: { lottieFile: string }) => (
  <LottiePlayer
    className="h-full w-full [&>svg]:max-h-56 [&>svg]:max-w-56 [&>svg]:md:max-h-none [&>svg]:md:max-w-none"
    loop
    animationData={JSON.parse(lottieFile)}
    play
    rendererSettings={{
      preserveAspectRatio: 'xMidYMid slice',
    }}
  />
)
