import React from 'react'
import { Footer, Header, HeaderDataProps, TopBar } from '..'
import clsx from 'clsx'

export const PageChrome = ({
  header,
  className,
  children,
}: {
  header?: HeaderDataProps[]
  className?: string
  children: any
}) => {
  const hasHeader = !!header?.find((data: any) => data.title)

  return (
    <div className={clsx('mx-auto grid grid-cols-1 bg-[#F6F6F6]', className)}>
      <TopBar />
      {hasHeader && <Header data={header as HeaderDataProps[]} />}
      <main>{children}</main>
      <Footer />
    </div>
  )
}
