import React, { useState } from 'react'
// @ts-ignore
import close from '../../../images/Close_Announcement.png'
import { SmartLink } from '../..'

type AnnouncementBannerData = {
  announcement: string
  bannerColor: string
  linkto?: string
}[]

export const AnnouncementBanner = ({
  data,
}: {
  data: AnnouncementBannerData
}) => {
  // First, check if there is a provenanceAnnouncements item in local storage
  let closedAnnouncements = ['']
  const currentLocalStorage = localStorage.getItem('provenanceAnnouncements')
  if (!currentLocalStorage) {
    // If undefined, set the item in local storage
    localStorage.setItem('provenanceAnnouncements', '')
  } else {
    closedAnnouncements = JSON.parse(currentLocalStorage)
  }

  // Determine if we should show anything
  // Go through queried announcements. If
  // they aren't in local storage, show the
  // announcement banner
  let shouldShow = false
  data.forEach(item =>
    closedAnnouncements.find(closed => {
      if (item.announcement === closed) {
        shouldShow = false
      } else {
        shouldShow = true
      }
    })
  )
  // Set local state
  const [show, setShow] = useState(shouldShow)
  // If closing the top bar, push the new announcements to local storage
  const handleAnnouncementClose = () => {
    // Add announcements to the closedAnnouncements array
    data.forEach(item => closedAnnouncements.push(item.announcement))
    // Push the stringified version to local storage
    localStorage.setItem(
      'provenanceAnnouncements',
      JSON.stringify(closedAnnouncements)
    )
    setShow(false)
  }

  return (
    <div
      className={`${
        show ? 'w-[100vw] bg-blue-700 p-3 text-sm text-white' : 'hidden'
      }`}
    >
      {data.map(
        item =>
          !closedAnnouncements.find(closed => item.announcement === closed) && (
            <div
              key={item.announcement}
              className="flex max-w-page justify-center gap-2 align-middle md:mx-auto"
            >
              <div>{item.announcement}</div>
              <hr className="h-auto w-px bg-blue-400 text-blue-400" />
              {item.linkto && (
                <SmartLink
                  linkTo={item.linkto}
                  className="font-bold underline underline-offset-2"
                >{`Learn More >`}</SmartLink>
              )}
            </div>
          )
      )}
      <img
        src={close}
        alt="close announcement banner"
        className="absolute right-[10px] top-0 cursor-pointer p-4"
        onClick={() => handleAnnouncementClose()}
      />
    </div>
  )
}
