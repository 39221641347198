import React from 'react'
import {
  FooterMenuHeader,
  FooterMenuSubheader,
  FooterMenuContent,
} from './Components'
import { graphql, useStaticQuery } from 'gatsby'
import { useMediaQuery } from '../../../hooks'
import { breakpoints } from '../../../consts'

export const FooterMenu = () => {
  const {
    datoCmsFooter: { content },
  } = useStaticQuery(graphql`
    {
      datoCmsFooter {
        content {
          content {
            ... on DatoCmsFooterMenu {
              footerMenuItems {
                footerHeaderContent {
                  ... on DatoCmsFooterMenuSubheader {
                    footerMenuSubheader
                    footerSubheaderContent {
                      footerMenuContent
                      linkto
                    }
                  }
                  ... on DatoCmsFooterMenuContent {
                    footerMenuContent
                    linkto
                  }
                }
                footerMenuHeader
                linkTo
              }
            }
          }
        }
      }
    }
  `)

  // Pull out the footer menu items from the query
  const footerMenuItems: any[] = content[0].content.find(
    (item: any) => item.footerMenuItems,
  ).footerMenuItems

  // Loop through menu items and build the footer menu
  const buildFooterMenu = (data: any) =>
    data.length > 0 &&
    data.map((menuItem: any) => (
      // First, grab the header title and build the header component
      <FooterMenuHeader
        header={menuItem.footerMenuHeader}
        key={menuItem.footerMenuHeader}
        linkTo={menuItem.linkTo}
      >
        {/* If the header has content, then start looping through the content */}
        {menuItem.footerHeaderContent.length > 0 &&
          menuItem.footerHeaderContent.map((headerContent: any) =>
            /* If the content has a subheader, display the content inside the subheader */
            headerContent.footerMenuSubheader ? (
              <FooterMenuSubheader
                subheader={headerContent.footerMenuSubheader}
                key={headerContent.footerMenuSubheader}
              >
                {/* Then display the subheader content here */}
                {headerContent.footerSubheaderContent.length > 0 &&
                  headerContent.footerSubheaderContent.map((content: any) => (
                    <FooterMenuContent
                      content={content.footerMenuContent}
                      key={content.footerMenuContent}
                      linkTo={content.linkto}
                    />
                  ))}
              </FooterMenuSubheader>
            ) : (
              // In some cases, there are no subheaders, so just render the content
              <FooterMenuContent
                content={headerContent.footerMenuContent}
                key={headerContent.footerMenuContent}
                linkTo={headerContent.linkto}
              />
            ),
          )}
      </FooterMenuHeader>
    ))

  // In the desktop view, we want the first element in it's own column and the rest in another column
  const buildGridEntries = () => (
    <>
      <div>{buildFooterMenu(footerMenuItems.slice(0, 1))}</div>
      <div>{buildFooterMenu(footerMenuItems.slice(1))}</div>
    </>
  )

  const { matches: renderMobile } = useMediaQuery(breakpoints.down('xl'))

  return (
    <div className="grid w-full grid-cols-1 justify-items-center gap-5 xl:grid-cols-3">
      {renderMobile ? buildFooterMenu(footerMenuItems) : buildGridEntries()}
    </div>
  )
}
