import clsx from 'clsx'
import React from 'react'
import { ImageType } from '../../templates/Generic'
import { Spacing, useSpacing } from '../hooks'
import { SmartLink } from '../Link'
import { TextComponent, TextComponentProps } from '../TextComponent'

export interface PeopleProps {
  spacing: Spacing[]
  titleSection: TextComponentProps[]
  backgroundColor: string
  peopleCardList: {
    headshot: ImageType
    id: string
    name: string
    title: string
    linkTo: string
  }[]
}

export const People = ({ data }: { data: PeopleProps }) => {
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  return (
    <div className={`${spacing} ${data.backgroundColor}`}>
      <div className="max-w-page md:mx-auto">
        <TextComponent data={data.titleSection} standalone />
        <div
          className={`grid grid-cols-2 gap-4 px-4 py-10 md:grid-cols-5 xl:gap-10 xl:px-[140px]`}
        >
          {data.peopleCardList.map(person => (
            <div
              key={person.id}
              className={clsx(
                `flex w-full flex-col items-center justify-start justify-self-center rounded-[25px] bg-white p-4 md:max-w-[200px]`,
                {
                  'cursor-pointer hover:shadow-standard': person.linkTo,
                }
              )}
            >
              <SmartLink linkTo={person.linkTo}>
                <img
                  className="pb-4"
                  src={person.headshot.url as string}
                  alt={
                    person.headshot.alt ||
                    person.headshot.filename ||
                    `${person.name} headshot`
                  }
                />
                <div className="pb-1 text-center text-base font-bold text-blue-700">
                  {person.name}
                </div>
                <div className="text-center text-sm text-gray-600">
                  {person.title}
                </div>
              </SmartLink>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
