import React from 'react'

export const Message = ({
  message,
  success,
  failure,
}: {
  message?: string
  success?: boolean
  failure?: boolean
}) => (
  <>
    {success && <div className="text-center text-xl font-bold">{message}</div>}
    {failure && (
      <div className="mb-1 text-center text-red-500 md:col-span-2 md:text-xl">
        Something went wrong, please refresh the page and try again
      </div>
    )}
  </>
)
