import React, { useState } from 'react'
import clsx from 'clsx'
import validator from 'email-validator'
import { getCookie } from '../../../../utils'
import { graphql, useStaticQuery } from 'gatsby'

export const FooterSubscribe = () => {
  const {
    datoCmsFooter: { content },
  } = useStaticQuery(graphql`
    {
      datoCmsFooter {
        content {
          content {
            ... on DatoCmsFooterSubscribe {
              successMessage
              footerSubscribeContent {
                content
              }
              formId
            }
          }
        }
      }
    }
  `)

  const data = content[0].content.find((item: any) => item.formId)
  const formId = data?.formId
  const thankYouMessage = data?.successMessage
  const text = data?.footerSubscribeContent[0].content
  const [email, setEmail] = useState('')
  const [status, setStatus] = useState('')

  const handleSubmit = async (evt: any) => {
    evt.preventDefault()
    if (!validator.validate(email)) return setStatus('invalid')
    setStatus('pending')

    const ipRes = await fetch('https://jsonip.com/')
    const { ip: ipAddress } = await ipRes.json()

    const hutk = getCookie('hubspotutk')

    const res = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/22440300/${formId}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: [{ name: 'email', value: email }],
          context: {
            hutk,
            pageUri: location?.href,
            pageName: 'footer',
            ipAddress,
          },
        }),
      }
    )

    if (res.ok && res.status < 400) setStatus('completed')
    else setStatus('failed')
  }

  const pending = status === 'pending'
  const completed = status === 'completed'
  const failed = status === 'failed'
  const invalid = status === 'invalid'

  return (
    <>
      {completed ? (
        <div className={`text-white`}>{thankYouMessage}</div>
      ) : (
        <>
          {failed && (
            <div className={`mb-2 text-red-500`}>
              Something went wrong, please try again.
            </div>
          )}
          {invalid && (
            <div className={`mb-2 text-red-500`}>
              Please enter a valid email address.
            </div>
          )}
          <form
            onSubmit={handleSubmit}
            className={`${location} m-0 flex flex-col gap-[7px]`}
            noValidate
          >
            <label className={`text-base text-white`}>{text}</label>
            <div className="relative m-0 flex flex-col items-center text-xs text-blue-500">
              <input
                type="email"
                className={clsx(
                  `
                border-transparent mb-0
                h-[63px] w-full rounded border
                border-solid
                px-4 py-3`,
                  { 'border-red-500': invalid, 'text-red-500': invalid }
                )}
                disabled={pending}
                placeholder="EMAIL ADDRESS"
                onChange={e => {
                  setEmail(e.target.value)
                  setStatus('')
                }}
                value={email}
              />
              <button
                type="submit"
                className="absolute right-[10px] top-[16px] text-xl text-blue-500"
              >{`>`}</button>
            </div>
          </form>
        </>
      )}
    </>
  )
}
