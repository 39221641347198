import React from 'react'
import {
  Button,
  type ButtonProps,
  TextComponent,
  TextSizeType,
  Spacing,
  useMediaQuery,
  useSpacing,
  TextComponentProps,
  useTextSize,
} from '..'
import { CardSubtitle, CardTitle } from './Components'
import { breakpoints } from '../consts'
import { ImageProps } from '../../templates/Generic'
import clsx from 'clsx'

export interface CardProps {
  spacing: Spacing[]
  titleSection: TextComponentProps[]
  cardList: {
    id: string
    backgroundColor: string
    backgroundImg: ImageProps
    darkbackground: boolean
    image: ImageProps[]
    size: 'large' | 'medium' | 'small'
    subtitle: string
    title: string
    button: ButtonProps[]
    text: TextSizeType
  }[]
}

// Function to build grids for cards
export const buildCards = ({
  data,
  size,
}: {
  data: CardProps['cardList']
  size: 'large' | 'medium' | 'small' | 'mobile'
}) => (
  <div
    className={clsx(`grid gap-8 pt-8`, {
      'grid-cols-1': size === 'large' || size === 'mobile',
      'grid-cols-2': size === 'medium',
      'grid-cols-3': size === 'small',
    })}
  >
    {data.map(card => (
      <div
        key={card.id}
        style={{
          backgroundImage: `url('${card.backgroundImg?.responsiveImage?.url}')`,
        }}
        className={clsx(
          `${card.backgroundColor} grid gap-8 rounded-3xl bg-cover p-12 shadow-standard`,
          {
            'grid-cols-2 items-center': size === 'large',
            'grid-cols-1 justify-items-center': size !== 'large',
          },
        )}
      >
        {size === 'large' ? (
          <>
            <div>
              {card.title && (
                <CardTitle
                  title={card.title}
                  darkImage={card.darkbackground}
                  size={size}
                />
              )}
              {card.subtitle && (
                <CardSubtitle
                  subtitle={card.subtitle}
                  darkImage={card.darkbackground}
                  textSize={
                    card.text.length === 0
                      ? 'text-2xl'
                      : useTextSize({ textSize: card.text })
                  }
                />
              )}
              {card.button.length > 0 && (
                <Button
                  className={clsx({
                    'mt-8': !(
                      card.image && card.image[0]?.responsiveImage?.url
                    ),
                    'my-8': card.image && card.image[0]?.responsiveImage?.url,
                  })}
                  btnType={card.button[0].btnType}
                  size={card.button[0].size}
                  linkTo={card.button[0].linkTo}
                >
                  {card.button[0].label}
                </Button>
              )}
            </div>
            {card.image && card.image[0]?.responsiveImage?.url && (
              <img
                className={clsx('justify-self-center align-middle', {
                  'pt-12': card.title || card.button.length > 0,
                })}
                src={card.image[0]?.responsiveImage?.url as string}
                alt={
                  card.image[0]?.responsiveImage?.alt ||
                  card.image[0]?.responsiveImage?.filename ||
                  `${card.title} icon`
                }
              />
            )}
          </>
        ) : (
          <div className="flex flex-col justify-between">
            <div>
              {card.title && (
                <CardTitle
                  title={card.title}
                  darkImage={card.darkbackground}
                  size={size}
                />
              )}
              {card.image[0]?.responsiveImage?.url && (
                <div
                  className={clsx('flex justify-center', {
                    'pt-12': card.title,
                  })}
                >
                  <img
                    src={card.image[0]?.responsiveImage?.url as string}
                    alt={
                      card.image[0]?.responsiveImage?.alt ||
                      card.image[0]?.responsiveImage?.filename ||
                      `${card.title} icon`
                    }
                  />
                </div>
              )}
              {card.subtitle && (
                <CardSubtitle
                  subtitle={card.subtitle}
                  darkImage={card.darkbackground}
                  textSize={
                    card.text.length === 0
                      ? 'text-2xl'
                      : useTextSize({ textSize: card.text })
                  }
                />
              )}
            </div>
            {card.button?.length > 0 && (
              <div className="self-bottom justify-self-start pt-8">
                <Button
                  btnType={card.button[0].btnType}
                  size={card.button[0].size}
                  linkTo={card.button[0].linkTo}
                >
                  {card.button[0].label}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    ))}
  </div>
)

/** Returns a card component to be rendered on the webpage */
export const Card = ({ data, ...rest }: { data: CardProps }) => {
  const { matches: renderMobile } = useMediaQuery(breakpoints.down('xl'))
  const spacing = useSpacing(data.spacing?.[0] as Spacing)

  const largeCards: CardProps['cardList'] = []
  const mediumCards: CardProps['cardList'] = []
  const smallCards: CardProps['cardList'] = []
  const allCards: CardProps['cardList'] = []
  // First, separate the provided cards into large, medium, small
  data.cardList.forEach(card =>
    renderMobile
      ? allCards.push(card)
      : card.size === 'large'
        ? largeCards.push(card)
        : card.size === 'medium'
          ? mediumCards.push(card)
          : smallCards.push(card),
  )
  // Cards will always render in size order
  return (
    <div className={clsx('max-w-page p-4 md:mx-auto md:px-24', spacing)}>
      <TextComponent data={data.titleSection} />
      {renderMobile ? (
        buildCards({ data: allCards, size: 'mobile' })
      ) : (
        <>
          {largeCards.length > 0 &&
            buildCards({ data: largeCards, size: 'large' })}
          {mediumCards.length > 0 &&
            buildCards({ data: mediumCards, size: 'medium' })}
          {smallCards.length > 0 &&
            buildCards({ data: smallCards, size: 'small' })}
        </>
      )}
    </div>
  )
}
