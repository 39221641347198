export type TextSizeType = {
  size: 'xl' | 'base' | 'sm'
}[]

/** React hook that returns a tailwindcss formatted string to
 * set the text size. DatCMS supports xl, base, and sm, and this
 * hook also supports custom sizes passed by the user.
 */
export const useTextSize = ({
  textSize = [
    {
      size: 'base',
    },
  ],
  customSize = '',
}: {
  textSize?: TextSizeType
  customSize?: string
}) =>
  `text-${
    customSize ? customSize : textSize.length > 0 ? textSize[0].size : 'base'
  }`
