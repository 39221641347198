import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { ImageType } from '../../../../templates/Generic'
import { SmartLink } from '../../../Link'

interface FooterSocialProps {
  image: ImageType
  linkTo: string
}
;[]

export const FooterSocial = () => {
  const {
    datoCmsFooter: { content },
  } = useStaticQuery(graphql`
    {
      datoCmsFooter {
        content {
          content {
            ... on DatoCmsFooterSocial {
              iconLinks {
                linkTo
                image {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  const icons = content[0].content.find((item: any) => item.iconLinks)?.iconLinks

  return (
    <div className={`mt-4 flex justify-between gap-[20px]`}>
      {icons?.map((icon: FooterSocialProps, index: number) => (
        <SmartLink key={index} linkTo={icon.linkTo}>
          <img
            className="fill-blue-300"
            src={icon.image?.url as string}
            alt={icon.image?.alt || icon.image?.filename || 'Social Media Icon'}
          />
        </SmartLink>
      ))}
    </div>
  )
}
