import React from 'react'

export interface VideoType {
  url: string
  provider: string
  height: number
  providerUid: string
  thumbnailUrl: string
  title: string
  width: number
}

const getVideoURL = (provider: string, providerUuid: string) => {
  switch (provider) {
    case 'youtube':
      return `https://www.youtube.com/embed/${providerUuid}`
    case 'vimeo':
      return `https://player.vimeo.com/video/${providerUuid}?h=bca8b328e2&title=0&byline=0&portrait=0`
    case 'facebook':
      return `https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2Ffacebookwatch%2Fvideos%2F${providerUuid}%2F&show_text=false&width=560&t=0`
    default:
      throw new Error("Provider isn't supported")
  }
}

export const VideoEmbed = ({
  className,
  video,
}: {
  className?: string
  video: VideoType
}) => (
  <iframe
    className={`aspect-video w-full ${className}`}
    src={getVideoURL(video.provider, video.providerUid)}
    title={video.title}
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowFullScreen
  />
)
