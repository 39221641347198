/**
 * Returns a boolean indicating whether the link is internal to the base URL.
 * @function
 * @param {string} link - The link to check for internalness.
 * @param {string} base - The base URL to compare against.
 * @returns {boolean} - true if the link is internal to the base URL, false otherwise.
 */
export const isInternal = (link: string, base: string): boolean => {
  const baseUrl = new URL(base)
  const url = new URL(link, base)
  return url.hostname === baseUrl.hostname
}
