export interface Spacing {
  marginBottom?: number
  marginTop?: number
  paddingBottom?: number
  paddingTop?: number
  marginBottomLg?: number
  marginTopLg?: number
  paddingBottomLg?: number
  paddingTopLg?: number
  hasX?: boolean
  paddingLeft?: number
  paddingRight?: number
  paddingLeftLg?: number
  paddingRightLg?: number
}

export const useSpacing = ({
  marginBottom = 0,
  marginTop = 0,
  paddingBottom = 0,
  paddingTop = 0,
  marginBottomLg = 0,
  marginTopLg = 0,
  paddingBottomLg = 0,
  paddingTopLg = 0,
  hasX = false,
  paddingLeft = 0,
  paddingRight = 0,
  paddingLeftLg = 0,
  paddingRightLg = 0,
}: Spacing = {}) => {
  const y = `mt-${marginTop} mb-${marginBottom} pt-${paddingTop} pb-${paddingBottom} md:mt-${marginTopLg} md:mb-${marginBottomLg} md:pt-${paddingTopLg} md:pb-${paddingBottomLg}`
  const x = `pl-${paddingLeft} pr-${paddingRight} md:pl-${paddingLeftLg} md:pr-${paddingRightLg}`
  return hasX ? `${y} ${x}` : y
}
