import React from 'react'
import { ImageType } from '../../templates/Generic'
import { type Spacing, useSpacing } from '../hooks'
import clsx from 'clsx'
import { Subhead } from '../TextAndContent/Subhead'

export interface TextAndImageProps {
  image: ImageType
  subhead: string
  spacing: Spacing[]
}

export const TextAndImage = ({ data }: { data: TextAndImageProps }) => {
  const spacing = useSpacing(data?.spacing?.[0] as Spacing)
  return (
    <div
      className={clsx(
        spacing,
        'flex max-w-page flex-col gap-8 p-4 md:mx-auto md:px-24'
      )}
    >
      <h2>
        <img
          src={data.image.url as string}
          alt={data.image.alt || data.image.filename || ''}
        />
      </h2>

      <Subhead subhead={data.subhead} />
    </div>
  )
}
