import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { SmartLink } from '../../../Link'

declare global {
  interface Window {
    displayPreferenceModal: () => void
  }
}

export const FooterCopyright = () => {
  const {
    datoCmsFooter: { content },
  } = useStaticQuery(graphql`
    {
      datoCmsFooter {
        content {
          content {
            ... on DatoCmsFooterCopyright {
              contact
              copyright
              tosLink
              privacyLink
            }
          }
        }
      }
    }
  `)

  const fcContent = content[0].content.find((item: any) => item.copyright)
  const { contact: email, privacyLink, tosLink, copyright } = fcContent

  const year = new Date().getFullYear()

  return (
    <div className="mt-2 w-[170px] text-center text-xs text-blue-400 xl:w-full xl:text-left">
      <p className="mb-2.5">
        {copyright && <span>&copy;</span>} {year} {copyright}{' '}
      </p>
      {email && (
        <p className="mb-2.5">
          All rights reserved{' '}
          <SmartLink className="underline" linkTo={`mailto:${email}`}>
            {email}
          </SmartLink>
        </p>
      )}
      {(privacyLink || tosLink) && (
        <p className="mb-2.5">
          {privacyLink && (
            <SmartLink className="block underline" linkTo={privacyLink}>
              Privacy Policy
            </SmartLink>
          )}
          {tosLink && (
            <SmartLink className="block underline" linkTo={tosLink}>
              Terms of Service
            </SmartLink>
          )}
        </p>
      )}
      <p>
        <a
          href="#"
          onClick={() => {
            window.displayPreferenceModal()
            return false
          }}
          id="termly-consent-preferences"
        >
          Consent Preferences
        </a>
      </p>
    </div>
  )
}
