import React from 'react'
import { Button, type ButtonProps } from '../Button'
import clsx from 'clsx'

export const ButtonGroup = ({
  buttons,
  className,
}: {
  buttons: ButtonProps[]
  className?: string
}) => (
  <div className={clsx('flex flex-row gap-4 sm:gap-8', className)}>
    {buttons.map(btn => (
      <Button
        key={btn.id}
        linkTo={btn.linkTo}
        btnType={btn.btnType}
        size={btn.size}
      >
        {btn.label}
      </Button>
    ))}
  </div>
)
