import React from 'react'
import clsx from 'clsx'
import { Markdown } from '../Markdown'

// Note that content applies py-4 that adds to py-8 around the content section
export const Content = ({
  content,
  darkMode,
  listLabel,
  listNum,
  textSize = 'text-xl',
}: {
  content: string
  darkMode: boolean
  listNum?: number
  listLabel?: string
  textSize: string
}) => (
  <>
    {listLabel && (
      <div
        className={clsx(`py-4 text-xl font-bold`, {
          'text-blue-400': darkMode,
        })}
      >
        {listLabel} {listNum}
      </div>
    )}
    <div
      className={clsx(`py-4 text-gray-700 ${textSize}`, {
        'text-blue-400': darkMode,
      })}
    >
      <Markdown markdown={content} />
    </div>
  </>
)
