import React from 'react'
import { VideoEmbed } from '../VideoEmbed'
import type { TextAndContentProps } from './TextAndContent'

export const ImageOrVideo = ({
  data,
  className = '',
}: {
  data: TextAndContentProps
  className?: string
}) => {
  if (data.section[0].video) {
    return <VideoEmbed className={className} video={data.section[0].video} />
  } else if (data.section[0].image) {
    return (
      <img
        className={className}
        src={data.section[0].image.url as string}
        alt={
          data.section[0].image.alt ||
          data.section[0].image.filename ||
          `${data.section[0].title} icon`
        }
      />
    )
  }

  return null
}
