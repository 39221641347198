import React from 'react'
import { SmartLink } from '..'

export const Sublink = ({
  title,
  className = '',
  arrowClassname = '',
  linkTo,
}: {
  title: string
  className?: string
  arrowClassname?: string
  linkTo: string
}) => (
  <SmartLink linkTo={linkTo}>
    <div
      className={`flex-cols flex cursor-pointer items-center gap-1 font-MaisonNeueExtended font-semibold ${className}`}
    >
      {title} {arrowClassname && <span className={arrowClassname}>{`>`}</span>}
    </div>
  </SmartLink>
)
