import React from 'react'
import { TopBarMenuItem } from './TopBarMenuItem'
import { TopNav } from './TopNav'
import { TopNavComplex } from './TopNavComplex'

export const TopBarMenu = ({
  menuItems,
  setShow,
  show,
}: {
  menuItems: any
  setShow: React.Dispatch<React.SetStateAction<string>>
  show: string
}) => (
  <div className={`flex w-max items-center gap-8`}>
    {menuItems.map((item: any) => {
      const isActive = show === item.title
      return (
        <div key={item.id} className="relative">
          <TopBarMenuItem
            title={item.title}
            showDropdown={isActive}
            hasDropdown={item.hasDropdown}
            onClick={
              item.hasDropdown
                ? () => setShow(isActive ? '' : item.title)
                : undefined
            }
            linkTo={!item.hasDropdown && item.linkto}
          />
          {item.hasDropdown && isActive && (
            <>
              {item.model.apiKey === 'top_navigation_section' && (
                <TopNav show={show} setShow={setShow} data={item} />
              )}
              {item.model.apiKey === 'top_navigation_section_complex' && (
                <TopNavComplex show={show} setShow={setShow} data={item} />
              )}
            </>
          )}
        </div>
      )
    })}
  </div>
)
