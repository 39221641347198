import clsx from 'clsx'
import React from 'react'
import { ImageProps, ImageType } from '../../templates/Generic'
import { Spacing, useSpacing } from '../hooks'
import { SmartLink } from '../Link'
import { TextComponent, TextComponentProps } from '../TextComponent'

export interface StepsProps {
  spacing: Spacing[]
  titleSection: TextComponentProps[]
  stepBackground: ImageType
  stepList: {
    content: string
    image?: ImageProps[]
    linkto?: string
    title: string
    stepIcon?: ImageType
  }[]
}

const buildSteps = ({
  step,
  index,
  stepBackground,
}: {
  step: StepsProps['stepList'][0]
  index: number
  stepBackground: ImageType
}) => (
  <div
    key={step.title}
    className={clsx(
      `grid grid-cols-1 items-center gap-8 border-b-2 p-8 last:border-0`,
      {
        'md:grid-cols-[1fr_9fr_2fr]': step.linkto,
        'md:grid-cols-[1fr_11fr]': !step.linkto,
      },
    )}
  >
    <div
      className="flex h-[48px] w-[42px] items-center justify-center bg-size-step text-xl font-bold text-blue-700 md:justify-self-center md:bg-center"
      style={{ backgroundImage: `url(${stepBackground.url})` }}
    >
      {step.stepIcon?.url ? (
        <img
          className="h-7 w-7"
          src={step.stepIcon.url}
          alt={
            step.stepIcon.alt || step.stepIcon.filename || `${step.title} icon`
          }
        />
      ) : (
        index
      )}
    </div>
    {/* The Title/Content components */}
    <div className="mb-8 grid grid-cols-1 md:mb-0">
      <h3 className="pb-2 text-xl font-bold text-blue-700">{step.title}</h3>
      <p className="text-gray-700">{step.content}</p>
    </div>
    {
      /* Image or clickable link components */
      (step.image?.length || step.linkto) && (
        <div className="md:justify-self-end">
          {step.image && step.image.length > 0 ? (
            <img
              className="max-w-[248px]"
              src={step.image[0].responsiveImage.url as string}
              alt={
                step.image[0].responsiveImage.alt ||
                step.image[0].responsiveImage.filename ||
                `${step.title} icon`
              }
            />
          ) : step.linkto ? (
            <SmartLink
              linkTo={step.linkto || ''}
              className={`
          pr-6
          font-MaisonNeueExtended
          text-[32px]
          font-bold
          text-gray-500
          no-underline
        `}
            >
              &gt;
            </SmartLink>
          ) : null}
        </div>
      )
    }
  </div>
)

export const Steps = ({ data }: { data: StepsProps }) => {
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  return (
    <div
      className={`max-w-page px-4 md:mx-auto md:px-24 ${spacing} min-[1440px]:grid min-[1440px]:grid-cols-[auto_auto] min-[1440px]:gap-16`}
    >
      <TextComponent
        data={data.titleSection}
        className="min-[1440px]:max-w-[500px]"
      />
      <div className="grid grid-cols-1 rounded-xl shadow-standard">
        {data.stepList.map((step, index) =>
          buildSteps({
            step,
            index: index + 1,
            stepBackground: data.stepBackground,
          }),
        )}
      </div>
    </div>
  )
}
