import React from 'react'
import format from 'date-fns/format'
import { render } from 'datocms-structured-text-to-plain-text'
import readingTime from 'reading-time'

type BlogMetaProps = {
  author: string
  date: string
  content: Queries.DatoCmsBlogPost['content']
}

export const BlogMeta = ({ author, content, date }: BlogMetaProps) => {
  const plainText = render(content as any)
  const dt = new Date(date)
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000)

  return (
    <div className="mx-4 mb-12 mt-16 flex justify-between border-b border-b-grey-600 text-lg">
      <span className="text-grey-600">
        {format(dtDateOnly, 'MM/dd/yyyy')}
        {plainText && (
          <span className="ml-2 border-l border-solid border-l-grey-600 pl-2">
            {readingTime(plainText).text}
          </span>
        )}
      </span>

      <span className="text-grey-600">{author}</span>
    </div>
  )
}
