import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { ImageType } from '../../../../templates/Generic'

export const FooterLogo = ({ className = '' }: { className?: string }) => {
  const {
    datoCmsFooter: { content: [{ content }] },
  } = useStaticQuery(graphql`
    {
      datoCmsFooter {
        content {
          content {
            ... on DatoCmsFooterLogo {
              logoImage {
                responsiveImage {
                  ...imageField
                }
              }
              logoVideo {
                ...imageField
              }
              logoSubtext
            }
          }
        }
      }
    }
  `)

  const data = content.flat().reduce((acc: {}, curr: {}) => ({...acc, ...curr}), {});
  const logoImage: ImageType =
  data.logoImage[0].responsiveImage
  const logoVideo: ImageType = data.logoVideo
  const subtext: string = data.logoSubtext

  return (
    <div className="grid grid-cols-1 pt-8">
      {logoVideo?.url ? (
        <video
          autoPlay
          muted
          playsInline
          id="video"
          className="items-right mb-5 justify-self-center"
        >
          <source src={logoVideo.url} type="video/mp4" />
        </video>
      ) : (
        <img
          className={`${subtext && 'mb-5'} items-right ${className}`}
          src={logoImage.url as string}
          alt={logoImage.alt as string}
        />
      )}
      {subtext && (
        <div className="max-w-[159px] text-center text-sm text-blue-300 xl:max-w-full xl:text-left">
          {subtext}
        </div>
      )}
    </div>
  )
}
