import React, { useEffect, useRef } from 'react'
import * as THREE from 'three'
;(globalThis as any).THREE = THREE // put THREE on the window

export const ThreeJs = ({ threeJsScript }: { threeJsScript: string }) => {
  const divRef = useRef<HTMLDivElement>(null)
  const isFirstRender = useRef(true)

  const html = `<script type="module">${threeJsScript}</script>`

  useEffect(() => {
    if (!html || !divRef.current || !isFirstRender.current) return
    isFirstRender.current = false // only let it render once

    const slotHtml = document.createRange().createContextualFragment(html) // Create a 'tiny' document and parse the html string
    divRef.current.innerHTML = '' // Clear the container
    divRef.current.appendChild(slotHtml) // Append the new content
  }, [html, divRef])

  return (
    <>
      <div id="animationContainer" />
      <div className="hidden" ref={divRef} />
    </>
  )
}
