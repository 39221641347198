import React from 'react'
import clsx from 'clsx'

export const Label = ({
  label,
  darkMode,
}: {
  label: string
  darkMode: boolean
}) => (
  <span
    className={clsx(`pb-4 text-sm font-semibold text-blue-700`, {
      'text-blue-500': darkMode,
    })}
  >
    {label}
  </span>
)
