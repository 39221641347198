import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { isEmpty } from '../../utils'

type BlogHeaderProps = {
  briefDescription?: string | null
  hideImage?: boolean | null
  image?: {
    alt: string | null
    filename: string | null
    gatsbyImageData: any
  } | null
  tag?: {
    id: string | null
    name: string | null
  } | null
  title?: string | null
}

export const BlogHeader = ({
  briefDescription,
  hideImage,
  image,
  tag,
  title,
}: BlogHeaderProps) => (
  <div className="mx-auto max-w-[1000px] px-4">
    <span key={tag?.id} className="text-sm text-blue-700">
      {tag?.name}
    </span>
    <h1 className="mb-4 max-w-[800px] font-MaisonNeueExtended text-2xl font-bold uppercase text-blue-900 sm:text-5xl">
      {title}
    </h1>
    <h2 className="block max-w-xl text-xl text-blue-700">{briefDescription}</h2>
    {!isEmpty(image) && !hideImage && (
      <GatsbyImage
        className="mx-auto mb-12 mt-16 flex max-w-[800px] items-center"
        image={image?.gatsbyImageData}
        alt={image?.alt || image?.filename || ''}
      />
    )}
  </div>
)
