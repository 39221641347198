import React from 'react'
import { SmartLink } from '../../../../Link'

export const FooterMenuContent = ({
  content,
  linkTo,
}: {
  content: string
  linkTo?: string
}) => (
  <div className="my-1.5 text-sm text-blue-100">
    <SmartLink
      linkTo={linkTo || ''}
      className="no-underline hover:text-blue-100"
    >
      {content}
    </SmartLink>
  </div>
)
