import React from 'react'
import { Spacing, useSpacing } from '../hooks'
import { SmartLink } from '../Link'
import { TextComponent, TextComponentProps } from '../TextComponent'

export interface WhoWereForProps {
  spacing: Spacing[]
  titleSection: TextComponentProps[]
  whoWereForCardlist: {
    title: string
    content: string
    linkto: string
  }[]
}

export const WhoWereFor = ({ data }: { data: WhoWereForProps }) => {
  const spacing = useSpacing(data.spacing?.[0] as Spacing)
  return (
    <div className={`max-w-page px-4 py-16 md:mx-auto md:px-24 ${spacing}`}>
      <TextComponent data={data.titleSection} />
      <div className="cols-1 grid gap-8 md:grid-cols-2">
        {data.whoWereForCardlist.map(card => (
          <SmartLink
            key={card.title}
            linkTo={card.linkto}
            className="rounded-lg bg-gray-200 py-[16px] px-[15px] no-underline"
          >
            <div className="flex items-center justify-between">
              <h3 className="text-xl font-semibold text-gray-900">
                {card.title}
              </h3>
              {card.linkto && (
                <span className="text-base font-normal text-[#120338]">{`>`}</span>
              )}
            </div>
            <p className="text-base font-normal text-black">{card.content}</p>
          </SmartLink>
        ))}
      </div>
    </div>
  )
}
