import React, { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from '../../hooks'
import { TopNavTitle } from './TopNavTitle'
import { TopNavMenu } from './TopNavMenu'
import clsx from 'clsx'

export const TopNav = ({
  show,
  data,
  setShow,
}: {
  show: string
  data: any
  setShow: React.Dispatch<React.SetStateAction<string>>
}) => {
  const wrapperRef = useRef(null)
  useOnClickOutside(wrapperRef, () => setShow(''))
  const [fadeIn, setFadeIn] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setFadeIn(true)
    }, 1)
  }, [])

  return data?.dropdownLinks?.length > 0 ? (
    <div
      ref={wrapperRef}
      className={clsx(
        `z-50 mt-4 bg-white lg:left-0 lg:rounded-[2rem] lg:px-12 lg:py-8 lg:shadow-standard-bottom-only`,
        `transition-opacity duration-300`,
        {
          'opacity-0': !fadeIn,
          'opacity-1': fadeIn,
          'static lg:absolute': show,
          hidden: !show,
        },
      )}
    >
      <TopNavTitle
        title={data.dropdownTitle}
        content={data.dropdownSubtitle}
        link={data.linkto || ''}
        isHeader
      />
      <TopNavMenu data={data.dropdownLinks} />
    </div>
  ) : null
}
