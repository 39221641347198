import React from 'react'
import { Button, Sublink } from '../../../components'

// Generate top buttons to be used in both mobile and desktop menus
export const TopBarButtons = ({ topBarButtons }: { topBarButtons: any }) => (
  <div className="flex-cols flex items-center gap-4 lg:justify-end">
    {topBarButtons.map((item: any) =>
      item.label ? (
        <Button
          key={item.label}
          linkTo={item.linkTo}
          btnType={item.btnType}
          size={item.size}
        >
          {item.label}
        </Button>
      ) : (
        <Sublink
          key={item.title}
          linkTo={item.linkTo}
          title={item.title}
          className="font-semibold text-gray-900"
          arrowClassname="text-blue-700 text-xl font-bold"
        />
      ),
    )}
  </div>
)
