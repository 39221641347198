import React from 'react'
import clsx from 'clsx'
import { Spacing, useSpacing } from '../hooks'

interface SeperatorProps {
  separatorColor: string
  separatorColorVariation: string
  spacing: Spacing[]
  thickness: string
}

export const Separator = ({ data }: { data: SeperatorProps }) => {
  const spacing = useSpacing({
    ...data.spacing.reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    hasX: true,
  } as Spacing)

  return (
    <div
      className={clsx(
        'mx-auto flex max-w-page items-center justify-center',
        spacing
      )}
    >
      <div
        className={clsx(
          'min-h-[1px]',
          'w-full',
          'border-b-solid',
          `border-b-${data.separatorColor}-${data.separatorColorVariation}`,
          {
            [`border-b-${data.thickness}`]: Number(data.thickness) > 1,
            'border-b': data.thickness === '1',
          }
        )}
      />
    </div>
  )
}
