import React from 'react'
import { Link } from 'gatsby'
import { isActiveUrl, isEmpty, isInternal } from '../../utils'
import { useSiteMetadata } from '../hooks'
import clsx from 'clsx'

interface SmartLinkProps {
  children: React.ReactNode
  linkTo?: string
  className?: string
  activeClassName?: string
  inactiveClassName?: string
  [key: string]: any
}

/**
 * SmartLink component is a component that receives a link and it will decide whether the link should redirect to an internal route or an external route.
 * @function
 * @param {Object} props -  The props object that contains the children, linkTo, activeClassName, inactiveClassName and any other props.
 * @returns {JSX.Element} -  Returns the JSX element of the component that matches the type of the item.
 */
export const SmartLink = ({
  children,
  linkTo,
  activeClassName = '',
  inactiveClassName = '',
  ...rest
}: SmartLinkProps) => {
  const { siteUrl } = useSiteMetadata()

  // Regex to test URLs without breaking the page
  const regex = new RegExp(
    '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
  )
  const badLink = linkTo && !isInternal(linkTo, siteUrl) && !regex.test(linkTo)

  if (!linkTo || badLink) {
    if (badLink) console.error('Incorrectly formatted url found: ', linkTo)
    return isEmpty(rest) ? (
      <>{children}</>
    ) : (
      <div children={children} {...rest} />
    )
  }

  const isActive = isActiveUrl(linkTo, siteUrl)

  // Adding no-underline to remove underline used in markdown
  if (isInternal(linkTo, siteUrl)) {
    const to = new URL(linkTo, siteUrl)
    return (
      <Link
        to={to.href.replace(to.origin, '')}
        children={children}
        className={clsx(`hover:text-inherit no-underline ${rest.className}`, {
          [activeClassName]: isActive,
          [inactiveClassName]: !isActive,
        })}
        {...rest}
      />
    )
  }

  return (
    <a
      className="hover:text-inherit no-underline"
      href={linkTo}
      target="_blank"
      rel="noreferrer"
      children={children}
      {...rest}
    />
  )
}
