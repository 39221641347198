import { PageProps } from 'gatsby'
import { isInternal } from './isInternal'

export const isActiveUrl = (link: string, base: string): boolean => {
  const loc = window.location
  const url = new URL(link, base)
  return (
    `${url.pathname}${url.search}${url.hash}` ===
    `${loc.pathname}${loc.search}${loc.hash}`
  )
}
