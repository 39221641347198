import React from 'react'
import { SmartLink } from './SmartLink'

export interface IconLinkProps {
  linkTo: string | null
  image: {
    url: string | null
    alt: string | null
    filename: string | null
  }
}

export const IconLink = ({ linkTo, image }: IconLinkProps) => (
  <SmartLink linkTo={linkTo || ''}>
    <img
      src={image?.url || ''}
      alt={image?.alt || image?.filename || 'social network link'}
    />
  </SmartLink>
)
