import React, { ReactNode } from 'react'

export const FooterMenuSubheader = ({
  subheader,
  children,
}: {
  subheader: string
  children?: ReactNode
}) => (
  <>
    <div
      className={`mt-3 font-bold text-blue-500 ${
        children ? 'mb-1' : 'mb-2'
      } text-left text-base`}
    >
      {subheader}
    </div>
    {children}
  </>
)
