import React, { useRef, useState } from 'react'
import clsx from 'clsx'
import validator from 'email-validator'
import { getCookie } from '../../utils'
import { Button } from '../Button'

const BlogAuthorSubscribe = ({
  name,
  title,
}: {
  name: string
  title: string
}) => {
  const emailRef = useRef<HTMLInputElement>(null)
  const [status, setStatus] = useState('')

  const handleSubmit = async (evt: React.SyntheticEvent) => {
    evt.preventDefault()
    if (!emailRef.current || !validator.validate(emailRef.current.value)) {
      return setStatus('invalid')
    }
    setStatus('pending')

    const ipRes = await fetch('https://jsonip.com/')
    const { ip: ipAddress } = await ipRes.json()

    const hutk = getCookie('hubspotutk')

    const res = await fetch(
      `https://api.hsforms.com/submissions/v3/integration/submit/22440300/1cac7102-05bd-4746-ab3c-cee4ea81e044`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: [
            { name: 'email', value: emailRef },
            { name: 'author', value: name },
          ],
          context: {
            hutk,
            pageUri: location?.href,
            pageName: title,
            ipAddress,
          },
        }),
      },
    )

    if (res.ok && res.status < 400) setStatus('completed')
    else setStatus('failed')
  }

  const pending = status === 'pending'
  const completed = status === 'completed'
  const failed = status === 'failed'
  const invalid = status === 'invalid'

  return (
    <form
      onSubmit={handleSubmit}
      className="grid w-full grid-cols-1 place-items-center gap-2 sm:grid-cols-input-btn sm:justify-items-start"
    >
      <label className="col-span-full text-lg font-medium text-grey-600">
        Subscribe to {name}'s personal blog
      </label>
      <input
        ref={emailRef}
        className={`
                  w-full
                  rounded
                  border
                  border-solid
                  border-grey-300
                  px-4
                  py-2
                  placeholder:text-xs
                  placeholder:uppercase
                  placeholder:text-blue-500
                `}
        type="text"
        placeholder="Email Address"
      />
      <Button type="submit">Submit</Button>
    </form>
  )
}

export default BlogAuthorSubscribe
