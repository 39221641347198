import React, { useState, useEffect, ReactElement } from 'react'

export const SiteContainer = ({ children }: { children: ReactElement }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(true)
  }, [])

  return (loaded && children) || null
}
