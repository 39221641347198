import clsx from 'clsx'
import React, { ReactNode } from 'react'
import { breakpoints } from '../../../../consts'
import { useMediaQuery } from '../../../../hooks'
import { SmartLink } from '../../../../Link'

export const FooterMenuHeader = ({
  header,
  children,
  linkTo = '',
}: {
  header: string
  children?: ReactNode
  linkTo?: string
}) => {
  const { matches: renderMobile } = useMediaQuery(breakpoints.down('xl'))
  return (
    <div className="w-full">
      <SmartLink linkTo={linkTo}>
        <div className={`border-b border-yellow-400 pb-1 pt-8`}>
          <div className="text-left font-MaisonNeueExtended text-[20px] font-medium text-white">
            {header} <span className="text-yellow-400">{' >'}</span>
          </div>
        </div>
      </SmartLink>
      {children}
    </div>
  )
}
