import React from 'react'
import clsx from 'clsx'

export const Subhead = ({
  subhead,
  darkMode = false,
}: {
  subhead: string
  darkMode?: boolean
}) => (
  <h3
    className={clsx(`text-2xl font-normal text-gray-700`, {
      'text-blue-300': darkMode,
    })}
  >
    {subhead}
  </h3>
)
