import React, { ElementType, ReactElement, ReactNode } from 'react'

export type GridProps = Queries.DatoCmsGridInfo & {
  children: any
  className?: string
}

export const Grid = ({
  colsMobile = 1,
  colsTablet = 2,
  colsDesktop = 3,
  children,
  className = '',
  gapMobile = 1,
  gapTablet = 2,
  gapDesktop = 3,
}: GridProps) => (
  <div
    className={`
        grid
        gap-${gapMobile} md:gap-${gapTablet} lg:gap-${gapDesktop}
        grid-cols-${colsMobile} md:grid-cols-${colsTablet} lg:grid-cols-${colsDesktop}
        ${className}
      `}
  >
    {children}
  </div>
)
