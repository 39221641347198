import React from 'react'
import clsx from 'clsx'
import type { ButtonProps } from '../Button'
import { ButtonGroup } from '../TextAndContent/ButtonGroup'
import { Lottie } from './Lottie'
import { ThreeJs } from './ThreeJs'

export type HeaderDataProps = {
  id: string
  anchorTag: string
  bgGradientStart?: string
  bgGradientEnd?: string
  buttons: ButtonProps[]
  direction: 'horizontal' | 'vertical'
  image?: {
    url: string
    alt?: string
  }
  lottieFile: string
  title?: string
  subtitle?: string
  textGradientStart?: string
  textGradientEnd?: string
  threeJs?: string
}

export const Header = ({ data }: { data: HeaderDataProps[] }) => {
  return data.map(item => {
    const isHorizontal = item.direction === 'horizontal'
    const isVertical = item.direction === 'vertical'
    return (
      <div
        key={item.id}
        className={`
          bg-gradient-to-br from-5% to-95%
          from-${item.bgGradientStart || 'gray-300'}
          to-${item.bgGradientEnd || 'white'}
        `}
      >
        <div
          key={item.id}
          className={clsx(
            'mt-28 grid grid-cols-1 place-items-center pt-16 md:pt-24',
            {
              'max-w-page gap-x-0 px-4 sm:grid-cols-2 md:mx-auto md:grid-cols-[55%_1fr] md:gap-x-16 md:px-24 xl:gap-x-32':
                isHorizontal,
              'gap-16 text-center': isVertical,
              'pb-8 md:pb-[4.5rem] lg:pb-32':
                (isVertical && !item.image?.url) || isHorizontal,
            },
          )}
        >
          <div
            className={clsx({
              'col-span-2 md:col-span-1 ': isHorizontal,
              'max-w-page px-4 md:mx-auto md:px-24': isVertical,
            })}
          >
            <h1
              className={clsx(
                `inline-block bg-gradient-to-br from-15% to-70%`,
                `bg-clip-text font-MaisonNeueExtended font-bold text-transparent xl:leading-snug`,
                `from-${item.textGradientStart || 'blue-600'} to-${item.textGradientEnd || 'blue-500'}`,
                {
                  'text-5xl xl:text-[4.575rem]': isHorizontal,
                  'text-4xl md:text-7xl xl:text-[4.625rem]': isVertical,
                },
              )}
            >
              {item.title}
            </h1>
            <h2
              className={clsx('text-gray-900 ', {
                'mt-4 text-lg md:my-12 xl:text-xl': isHorizontal,
                'mt-8 text-lg md:text-xl xl:mt-16 xl:text-3xl': isVertical,
              })}
            >
              {item.subtitle}
            </h2>
          </div>
          <ButtonGroup
            buttons={item.buttons}
            className={clsx({
              'relative z-10 justify-self-start sm:static md:col-start-1 md:row-start-2':
                isHorizontal,
            })}
          />
          {(item.lottieFile || item.image?.url || item.threeJs) && (
            <div
              className={clsx({
                'max-h-56 max-w-56 justify-self-end md:row-span-2 md:max-h-none md:max-w-none md:justify-self-auto':
                  isHorizontal,
              })}
            >
              {item.threeJs ? (
                <ThreeJs threeJsScript={item.threeJs} />
              ) : item.lottieFile && isHorizontal ? (
                <Lottie lottieFile={item.lottieFile} />
              ) : (
                <img
                  src={item.image?.url}
                  alt={item.image?.alt}
                  className={clsx({
                    'max-h-56 max-w-56 md:max-h-none md:max-w-none':
                      isHorizontal,
                  })}
                />
              )}
            </div>
          )}
        </div>
      </div>
    )
  })
}
