import React from 'react'
import Input, { isValidPhoneNumber } from 'react-phone-number-input/input'
import { FieldErrors, FieldValues } from 'react-hook-form'

export const handlePhoneValidation = (value: string) =>
  isValidPhoneNumber(value)

export const Telephone = ({
  value,
  onChange,
  errors,
  name,
}: {
  value: string
  name: string
  onChange: any
  errors: FieldErrors<FieldValues>
}) => (
  <div className="inherit">
    <Input
      value={value}
      onChange={onChange}
      defaultCountry="US"
      className={`w-full rounded-lg border border-gray-900 p-3`}
    />
    {errors[name] && (
      <p className="text-sm font-normal text-red-500" role="alert">
        Please enter a valid telephone number
      </p>
    )}
  </div>
)
