import React from 'react'
import clsx from 'clsx'
import { Markdown } from '../..'

export const CardSubtitle = ({
  subtitle,
  darkImage = false,
  textSize,
}: {
  subtitle: string
  darkImage: boolean
  textSize: string
}) => (
  <div
    className={clsx(`pt-12 text-left ${textSize}`, {
      'text-blue-300': darkImage,
      'text-gray-600': !darkImage,
    })}
  >
    <Markdown markdown={subtitle} />
  </div>
)
